// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #209e91;
  --ion-color-primary-rgb: 32, 158, 145;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1c8b80;
  --ion-color-primary-tint: #36a89c;

  --ion-color-secondary: #2bacd1;
  --ion-color-secondary-rgb: 43, 172, 209;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #2697b8;
  --ion-color-secondary-tint: #40b4d6;

  --ion-color-tertiary: #ccccb3;
  --ion-color-tertiary-rgb: 204, 204, 179;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #b4b49e;
  --ion-color-tertiary-tint: #d1d1bb;

  --ion-color-success: #209e91;
  --ion-color-success-rgb: 32, 158, 145;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #1c8b80;
  --ion-color-success-tint: #36a89c;

  --ion-color-warning: #dfb81c;
  --ion-color-warning-rgb: 223, 184, 28;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c4a219;
  --ion-color-warning-tint: #e2bf33;

  --ion-color-danger: #e85656;
  --ion-color-danger-rgb: 232, 86, 86;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #cc4c4c;
  --ion-color-danger-tint: #ea6767;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/*
 * Dark Colors
 * -------------------------------------------
 */

body.dark {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #1c1c1c;
  --ion-item-background-activated: #313131;
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1a1b1e;
}

.deleteImage {
  color: var(--ion-color-danger) !important;

  button {
    color: var(--ion-color-danger) !important;
  }
}

.alertServiceButtonBold {
  border-right: none !important;
  border-bottom: none !important;
  font-weight: bold !important;
}

.alertTextBold {
  font-weight: bold !important;
}

.alertServiceButtonNormal {
  border-right: none !important;
  border-bottom: none !important;
  font-weight: normal !important;
}

.alertTextNormal {
  font-weight: normal !important;
}

.alertExport {
  input {
    border: none !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  input[disabled] {
    opacity: 1 !important;
    color: var(--ion-text-color, #000) !important;
    font-weight: bold !important;
  }
}

.searchbar-input {
  font-size: smaller !important;
}

ion-content {
  --ion-background-color: var(--ion-color-light) !important;
}

ion-item {
  --ion-background-color: var(--ion-color-dark-contrast) !important;
}

ion-card {
  --background: var(--ion-color-dark-contrast) !important;
}

ion-list-header {
  background-color: transparent !important;
}

ion-item-divider {
  background-color: transparent !important;
}

swiper-container {
  --bullet-background-active: var(--ion-color-dark) !important;
}

$grid-breakpoints: (xs: 0,
  // Phone (0px - 575px)
  sm: 576px,
  // Phablet (576px - 767px)
  md: 768px,
  // Tablet vertical (768px - 991px)
  lg: 992px // Tablet horizontal, Desktop (992px and above),,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

$grid-max-widths: (lg: 992px,
);

ion-app {
  margin: 0 auto;
  max-width: #{map-get($grid-max-widths, lg)};
}

.deleteButton {
  color: var(--ion-color-danger) !important;
}