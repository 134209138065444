/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Necessary for calendar plugin */
@import 'swiper/css';

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #0054e9);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
  min-width: 0;
  display: grid;
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.validation-error {
  color: var(--ion-color-danger);
}

ion-modal.auto-size {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;
  --width: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
      border: solid 1px;
    }
  }
}

.custom-modal {
  --width: 100%;
  --height: 100%;
}

.datetime-modal {
  --height: 100% !important;
  --width: 100% !important;
  --background: rgba(44, 39, 45, 0.2);

  &::part(content) {
    backdrop-filter: blur(6px);
  }

  ion-content {
    --background: transparent;

    --padding-top: 25vh;
    --padding-start: 20px;
    --padding-end: 20px;

    --ion-datetime {
      border-radius: 8px;
    }
  }
}

.pop-over-style {
  --width: 350px !important;
  position: absolute !important;
}

ion-icon {
  color: var(--ion-color-medium);
}

// Applies a text wrap on ion options
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:horizontal {
  height: 20px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}

.frame::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.maintenance .alert-head {
  text-align: center !important;
}

.maintenance .alert-message {
  text-align: center !important;
  min-height: 350px !important;
}

.maintenance img {
  max-height: 100px;
  max-width: 100px;
  padding: 4px;
  width: 150px;
}

.four-button-alert.md {
  .alert-button-group-vertical {
    display: block;

    button:nth-of-type(1),
    button:nth-of-type(2) {
      float: left;
      color: var(--ion-color-medium);
    }

    button:nth-of-type(3),
    button:nth-of-type(4) {
      float: right;
    }
  }
}

.four-button-alert.ios {
  .alert-button-group-vertical {
    flex-direction: row !important;

    button:nth-of-type(1) {
      color: var(--ion-color-medium);
    }

    button:nth-of-type(2) {
      color: var(--ion-color-medium);
      border-right: none;
    }
  }
}

.scanner-ui {
  display: none;
}

.scanner-hide {
  visibility: visible;
}

body.qrscanner {
  background-color: transparent;
}

body.qrscanner .scanner-ui {
  display: block;
}

body.qrscanner .scanner-hide {
  visibility: hidden;
}